import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Profile } from 'store/api/profiles/profiles.interface'
import DaySelectCheckboxes, {
  DaySelectCheckbox,
} from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/Scheduling/DaySelectCheckboxes'
import TimeRangeSelect, {
  defaultScheduleEndTimeIndex,
  defaultScheduleStartTimeIndex,
} from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/Scheduling/TimeRangeSelect'
import { listOfTimes } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/Scheduling/Schedule.helpers'
import {
  TimeDropdownItemData,
  Weekdays,
} from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/Scheduling/Schedule.interface'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import { Flex } from '@theme-ui/components'
import {
  useGetSchedulesQuery,
  usePostSchedulesMutation,
  usePutSchedulesMutation,
  weekdaysObjectToArrayRepresentation,
} from 'store/api/schedules/schedules'
import ProfileSelectionDropDown, {
  ProfileDropDownItem,
} from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import { modalFooterRef } from 'ui/NewModalDialog/ModalDialogFooter'
import { createPortal } from 'react-dom'
import { hotfixTimezone } from 'utils/hotfixTimezone'
import omit from 'lodash/omit'
import { useScheduleState } from 'components/Dashboard/Devices/DeviceListItem/ScheduleContextProvider'
import { DropdownItemData } from 'components/Dashboard/Profiles/CustomRules/MoveRulesMenu'
import { DeviceClient, DeviceInfo } from 'store/api/devices/devices.interface'

const selectOptionDefaultName = 'Select an option'

interface ProfileDropdownItemData extends DropdownItemData {
  pk: string
}

export interface ScheduleDraft {
  startTime: TimeDropdownItemData
  endTime: TimeDropdownItemData
  days: Weekdays
  enforcedProfilePk: string
  isAllDay?: boolean
}

export default function ScheduleViewContent({
  device,
  dismiss,
  profiles,
  initialDraft,
}: {
  device?: DeviceInfo<DeviceClient[]>
  dismiss: () => void
  profiles: Profile[]
  initialDraft?: Partial<ScheduleDraft>
}): ReactElement {
  const [isRequestInFlight, setisRequestInFlight] = useState(false)
  const { data: schedulesData } = useGetSchedulesQuery('')
  const [postSchedule] = usePostSchedulesMutation()
  const [putSchedule] = usePutSchedulesMutation()
  const { existingSchedule } = useScheduleState()

  const onSaveSchedule = useCallback(
    async (draft: ScheduleDraft): Promise<void> => {
      if (draft.isAllDay) {
        draft = {
          ...draft,
          startTime: { time: '00:00', text: '' },
          endTime: { time: '00:00', text: '' },
        }
      }
      if (schedulesData?.schedules) {
        const commonAttrs = {
          time_start: draft.startTime.time,
          time_end: draft.endTime.time,
          weekdays: weekdaysObjectToArrayRepresentation(draft.days),
          time_zone: hotfixTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone),
          profile_id: draft.enforcedProfilePk,
          device_id: device?.PK || '',
        }

        let response
        if (existingSchedule) {
          response = await putSchedule({
            pk: existingSchedule.PK ?? '',
            body: omit(
              {
                ...existingSchedule,
                ...commonAttrs,
              },
              ['profile', 'device'],
            ),
          })
        } else {
          response = await postSchedule({
            name: device?.name || '',
            ...commonAttrs,
          })
        }

        if (!response.error) {
          dismiss()
        }
      }
    },
    [
      device?.PK,
      device?.name,
      dismiss,
      existingSchedule,
      postSchedule,
      putSchedule,
      schedulesData?.schedules,
    ],
  )

  const profileDropdownItems: ProfileDropdownItemData[] = profiles.map(profile => {
    return {
      text: `${profile.name}`,
      pk: profile.PK.toString(),
    }
  })
  const [draft, setDraft] = useState(initialDraft)

  const currentProfile: ProfileDropDownItem = useMemo(() => {
    return {
      text: `${
        profileDropdownItems.find(item => item.pk === draft?.enforcedProfilePk)?.text ||
        selectOptionDefaultName
      }`,
      name: `${
        profileDropdownItems.find(item => item.pk === draft?.enforcedProfilePk)?.text ||
        selectOptionDefaultName
      }`,
      PK: draft?.enforcedProfilePk || '',
    }
  }, [profileDropdownItems, draft])

  return (
    <Flex
      sx={{
        width: ['100%', '43.5rem'],
        maxHeight: ['calc(100% - 0.2rem)', '100%'],
        flexDirection: 'column',
        overflowY: 'auto',
        px: '1.6rem',
        py: '1.2rem',
      }}
    >
      <ProfileSelectionDropDown
        testId="device-schedule-enforced-profile"
        label="Enforced Profile"
        boundaryElementTestId="device-dialog"
        selectedProfile={currentProfile}
        setSelectedProfile={(selectedItem: ProfileDropDownItem) => {
          setDraft(prevDraft => {
            return { ...prevDraft, enforcedProfilePk: `${selectedItem.PK}` }
          })
        }}
        disabledProfilePk={device?.profile?.PK.toString()}
        description=""
        tooltipText="The Profile whose rules you want to enforce on this Device"
        sx={{
          width: '100%',
          p: 0,
        }}
      />
      <TimeRangeSelect
        startTime={draft?.startTime ?? listOfTimes[defaultScheduleStartTimeIndex]}
        endTime={draft?.endTime ?? listOfTimes[defaultScheduleEndTimeIndex]}
        setStartTime={startTime =>
          setDraft(oldDraft => {
            return { ...oldDraft, startTime: startTime as TimeDropdownItemData }
          })
        }
        setEndTime={endTime => {
          setDraft(oldDraft => {
            return { ...oldDraft, endTime: endTime as TimeDropdownItemData }
          })
        }}
        sx={{ mb: '1.6rem' }}
        isAllDaySelected={draft?.isAllDay}
      />
      <DaySelectCheckbox
        customName="All day"
        isSelected={!!draft?.isAllDay}
        toggleIsSelected={() => {
          setDraft(oldDraft => ({ ...oldDraft, isAllDay: !oldDraft?.isAllDay }))
        }}
      />
      <DaySelectCheckboxes
        selectedDays={
          draft?.days ?? {
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
            sun: 0,
          }
        }
        setSelectedDays={(selectedDaysSetStateAction: SetStateAction<Weekdays>): void => {
          setDraft(oldDraft => {
            let newSelectedDays: Weekdays
            if (typeof selectedDaysSetStateAction === 'object') {
              newSelectedDays = selectedDaysSetStateAction
            } else if (typeof selectedDaysSetStateAction === 'function') {
              newSelectedDays = selectedDaysSetStateAction(
                oldDraft?.days ?? {
                  mon: 0,
                  tue: 0,
                  wed: 0,
                  thu: 0,
                  fri: 0,
                  sat: 0,
                  sun: 0,
                },
              )
            } else {
              throw `invalid setStateAction passed to setSelectedDays: ${selectedDaysSetStateAction}`
            }

            return { ...oldDraft, days: newSelectedDays }
          })
        }}
      />
      <SaveScheduleButton
        testId="schedule-save-button"
        ariaLabel="save schedule button"
        isLoading={isRequestInFlight}
        isButtonDisabled={
          !Object.values(draft?.days ?? {}).some(p => !!p) ||
          !draft?.endTime ||
          !draft?.startTime ||
          !draft?.enforcedProfilePk
        }
        onClick={async e => {
          e.preventDefault()
          setisRequestInFlight(true)
          await onSaveSchedule(draft as ScheduleDraft)
          setisRequestInFlight(false)
        }}
      />
    </Flex>
  )
}

export function SaveScheduleButton({
  isButtonDisabled,
  isLoading,
  testId,
  ariaLabel,
  onClick,
}: {
  isButtonDisabled: boolean
  isLoading: boolean
  testId?: string
  ariaLabel: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}): ReactElement | null {
  const [shouldAppendButton, setShouldAppendButton] = useState(false)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current
    ? createPortal(
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonWithLoadingState
            isLoading={isLoading}
            variant="newPrimary"
            data-testid={testId}
            ariaLabel={ariaLabel}
            onClick={onClick}
            disabled={isButtonDisabled}
            sx={{
              minWidth: '14rem',
              width: ['100%', 'auto'],
              justifyContent: 'center',
              height: '3.2rem',
              alignItems: 'center',
              px: '1.6rem',
            }}
          >
            Save Schedule
          </ButtonWithLoadingState>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}
