import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Input } from 'ui'
import { Flex } from 'theme-ui'
import PasswordInput from 'components/PasswordInput'
import { PasswordValidationState, validatePassword } from 'utils/passwordValidation'

export const EmailEdit = ({
  setIsFormValid,
}: {
  setIsFormValid: (isValid: boolean) => void
}): ReactElement => {
  const [currentPwd, setCurrentPwd] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const emailRegex = RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)

  useEffect(() => {
    // the password length is checked to be at least 7 since old passwords were allowed to be 7 characters long.
    setIsFormValid(!!email.match(emailRegex)?.length && currentPwd.length >= 7)
  }, [currentPwd, email, setIsFormValid, emailRegex])
  return (
    <Flex
      sx={{
        flex: 1,
        width: ['100%', '100%', 'auto'],
        flexDirection: ['column', 'column', 'row'],
        alignItems: 'flex-start',
        gap: ['0.8rem', '0.8rem', 0],
      }}
    >
      <Flex
        sx={{
          flex: 1,
          width: ['100%', '100%', 'auto'],
          mr: [0, 0, '1.6rem'],
          mb: 0,
        }}
      >
        <Input
          isCompact
          variant="newPrimary"
          data-testid="new-email"
          autoComplete="new-email"
          placeholder="Email"
          aria-label="email"
          id="edit-profile-email"
          name="email"
          type="email"
          onChange={(e): void => setEmail(e.target.value)}
        />
      </Flex>
      <Flex sx={{ width: '100%', flex: 1 }}>
        <PasswordInput
          isCompact
          variant="newPrimary"
          data-testid="confirm-password"
          placeholder="Confirm Password"
          aria-label="confirm password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="confirm-password"
          id="edit-profile-conf-password"
          name="password"
          shouldShowShowPasswordButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          onChange={(e): void => setCurrentPwd(e.target.value)}
          sxShowPasswordStyle={{ top: '50%', right: '0.8rem', transform: 'translate(0, -50%)' }}
        />
      </Flex>
    </Flex>
  )
}

export const PasswordEdit = ({
  setIsFormValid,
}: {
  setIsFormValid: (isValid: boolean) => void
}): ReactElement => {
  const [currentPwd, setCurrentPwd] = useState('')
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [passwordHint, setPasswordHint] = useState('')
  const [passwordValidationState, setPasswordValidationState] = useState(
    PasswordValidationState.EMPTY,
  )

  useEffect(() => {
    setIsFormValid(passwordValidationState === PasswordValidationState.VALID)
  }, [currentPwd, passwordValidationState, setIsFormValid])
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: ['column', 'column', 'row'],
        alignItems: 'flex-start',
        gap: '1.2rem',
      }}
    >
      <Flex
        sx={{
          width: ['100%', '100%', 'auto'],
          flex: 1,
        }}
      >
        <Input
          isCompact
          variant="newPrimary"
          data-testid="old-password"
          placeholder="Current Password"
          aria-label="current password"
          autoComplete="old-password"
          type="password"
          id="edit-profile-old-password"
          name="oldPassword"
          onChange={(e): void => setCurrentPwd(e.target.value)}
        />
      </Flex>
      <Flex
        sx={{
          width: ['100%', '100%', 'auto'],
          flex: 1,
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <PasswordInput
          isCompact
          variant="newPrimary"
          data-testid="new-password"
          placeholder="New Password"
          aria-label="new password"
          id="edit-profile-new-password"
          type={showPassword ? 'text' : 'password'}
          name="newPassword"
          onChange={(e): void => {
            const password = e.currentTarget.value
            const tempPasswordValidationState = validatePassword(password)
            setPasswordValidationState(tempPasswordValidationState)
            setPasswordHint(tempPasswordValidationState.valueOf())
          }}
          ref={passwordInputRef}
          shouldShowShowPasswordButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          sxShowPasswordStyle={{ top: '50%', right: '1.2rem', transform: 'translate(0, -50%)' }}
        />
        {passwordHint && (
          <Flex
            sx={{
              fontSize: '1.4rem',
              pt: ['0.8rem'],
              pb: ['0.8rem', '0.8rem', 0],
              mx: 'auto',
            }}
            color={
              passwordValidationState !== PasswordValidationState.VALID ? 'pomegranate' : 'white50'
            }
          >
            {passwordHint}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
