import React, { ReactElement } from 'react'
import { EditDataRowProps } from 'components/Dashboard/Preferences/CardItems/DataRow.interface'
import { Flex, Text } from 'theme-ui'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { ActionButton } from './DataRow'

export default function EditableDataRow({
  name,
  leftContent,
  data,
  children,
  isEditing,
  setIsEditing,
  handleIconClick,
  disableEdit = false,
  sxContainer,
  bottomContent,
  testId,
  extraDataToAppend,
  sxRow,
  sxTitle,
}: EditDataRowProps): ReactElement {
  const handleClick = handleIconClick ? handleIconClick : () => setIsEditing?.(true)

  return (
    <Flex
      data-testid={testId}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: ['auto', '5.4rem'],
        backgroundColor: 'cardBg',
        borderRadius: [0, '1.2rem'],
        border: '1px solid',
        borderTopColor: ['transparent', 'blueYonder15'],
        borderLeftColor: ['transparent', 'blueYonder15'],
        borderRightColor: ['transparent', 'blueYonder15'],
        borderBottomColor: 'blueYonder15',
        flexDirection: 'column',
        alignItems: 'center',
        px: '1.2rem',
        py: ['0.8rem', '1.2rem'],
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.07)',
        ...sxRow,
      }}
    >
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: ['column', 'row', 'row'],
          alignItems: ['flex-start', 'center'],
          gap: '1.2rem',
          ...sxContainer,
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            flexShrink: [1, 0],
            width: 'fit-content',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            variant="size15Weight700"
            sx={{
              minWidth: ['100%', '20rem'],
              mr: [0, '1.2rem'],
              color: 'aliceBlue',
              alignSelf: 'baseline',
              ...sxTitle,
            }}
            tabIndex={name ? 0 : undefined}
          >
            {name}
          </Text>
          {leftContent && <Flex>{leftContent}</Flex>}
        </Flex>
        {isEditing ? (
          children
        ) : (
          <Flex
            sx={{
              flexDirection: 'row',
              flex: 1,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Flex
              sx={{
                width: '100%',
                color: 'aliceBlue60',
                alineItems: 'center',
                flexDirection: 'column',
              }}
            >
              {typeof data === 'string' ? (
                <TextWithOverFlowAndTippyPopup
                  variant="size15Weight500"
                  testId="profile-row-data"
                  sxText={{
                    pr: [disableEdit ? '1.2rem' : 'calc(6.4rem + 1.2rem)', '1.2rem'],
                    flex: 10,
                    width: '100%',
                    textAlign: 'left',
                    color: 'aliceBlue60Black60',
                  }}
                  content={data}
                  ariaLabel={data || ''}
                  sxTooltipContent={{
                    overflowWrap: 'anywhere',
                  }}
                />
              ) : (
                data
              )}
              {extraDataToAppend}
            </Flex>

            {!disableEdit && (
              <ActionButton
                data-testid={`edit-${name.toLocaleLowerCase()}`}
                ariaLabel={`edit ${name}`}
                onClick={handleClick}
              >
                Edit
              </ActionButton>
            )}
          </Flex>
        )}
      </Flex>
      {!isEditing && bottomContent}
    </Flex>
  )
}
