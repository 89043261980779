import React, { ReactElement, useCallback, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import { EnabledStatus, CustomRuleData } from 'store/api/rules'
import { usePutGroupRulesMutation } from 'store/api/rules/rules'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import { useAppSelector } from 'store/hooks'

interface StatusChangeProps {
  rulesToEdit?: CustomRuleData[]
  dismiss: () => void
}
export default function StatusChange({ rulesToEdit, dismiss }: StatusChangeProps): ReactElement {
  const [isEnableRequestInFlight, setEnableRequestInFlight] = useState(false)
  const [isDisableRequestInFlight, setDisableRequestInFlight] = useState(false)
  const [putMultipleRule] = usePutGroupRulesMutation()
  const rulesProfile = useGetSelectedProfile()
  const { groupUserIsIn } = useAppSelector(s => s.customRules)
  const handleClick = useCallback(
    (isEnableAllButton: boolean) => (): void => {
      if (isEnableAllButton) {
        setEnableRequestInFlight(true)
      } else {
        setDisableRequestInFlight(true)
      }
      putMultipleRule({
        body: {
          status: isEnableAllButton ? EnabledStatus.ENABLED : EnabledStatus.DISABLED,
          hostnames: rulesToEdit?.map(r => r.PK) ?? [],
          group: groupUserIsIn,
        },
        profileId: rulesProfile?.PK.toString() ?? '',
      })

      if (isEnableAllButton) {
        setEnableRequestInFlight(false)
      } else {
        setDisableRequestInFlight(false)
      }
      dismiss()
    },
    [dismiss, groupUserIsIn, putMultipleRule, rulesProfile?.PK, rulesToEdit],
  )
  return (
    <Flex sx={{ width: ['100%', 'fit-content'], gap: '1.2rem' }}>
      <ButtonWithLoadingState
        variant="roundedSecondary"
        data-testid="rule-tray-enable-all-button"
        ariaLabel="rule tray enable all button"
        sx={{
          minWidth: 'auto',
          width: '100%',
          height: '3.2rem',
          borderRadius: '2rem',
          py: 0,
          px: '1.6rem',
        }}
        disabled={!rulesToEdit?.some(r => r.action.status !== EnabledStatus.ENABLED)}
        isLoading={isEnableRequestInFlight}
        onClick={handleClick(true)}
      >
        <Text variant="size15Weight600">Enable</Text>
      </ButtonWithLoadingState>
      <ButtonWithLoadingState
        variant="roundedSecondary"
        data-testid="rule-tray-disable-all-button"
        ariaLabel="rule tray disable all button"
        sx={{
          minWidth: 'auto',
          width: '100%',
          height: '3.2rem',
          borderRadius: '2rem',
          py: 0,
          px: '1.6rem',
        }}
        disabled={!rulesToEdit?.some(r => r.action.status !== EnabledStatus.DISABLED)}
        isLoading={isDisableRequestInFlight}
        onClick={handleClick(false)}
      >
        <Text variant="size15Weight600">Disable</Text>
      </ButtonWithLoadingState>
    </Flex>
  )
}
