import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { IconButtonContent, Input, SimpleActionMenuItemProps } from 'ui'
import UploadIcon from 'images/upload.svg'
import SmallListIcon from 'images/small-list.svg'
import LargeListIcon from 'images/large-list.svg'
import SortIcon from 'images/dashboard/sort-icon.svg'
import { OnChooseFileCreator } from 'components/Dashboard/Profiles/CustomRules/hooks/useImportFileHandle'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  AllActionType,
  AllStatusesType,
  setRuleActionFilter,
  setRuleStatusFilter,
  setShouldShowLargeView,
  setShouldSortRules,
} from 'store/customRules'
import CheckmarkIcon from 'images/checkmark.svg'
import MoreButton from 'ui/MoreButton'
import LastUpdatedIcon from 'images/dashboard/last-updated.svg'
import ActionsMenu from './ActionsMenu'
import FilterByRuleItem from './FilterByRuleItem'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import StatusMenu from './StatusMenu'

interface OptionsMenuProps {
  onChooseFileCreator: OnChooseFileCreator
  tabIndex?: number
}

export default function OptionsMenu({
  onChooseFileCreator,
  tabIndex,
}: OptionsMenuProps): ReactElement {
  const hideRef = useRef<() => void>()
  const dispatch = useAppDispatch()
  const shouldSortRules = useAppSelector(s => s.customRules.shouldSortRules)
  const shouldShowLargeListView = useAppSelector(s => s.customRules.shouldShowLargeView)
  const [isAllActionsMenuVisible, setIsAllActionsMenuVisible] = useState(false)
  const [isAllStatusesMenuVisible, setIsAllStatusesMenuVisible] = useState(false)
  const ruleActionFilter = useAppSelector(s => s.customRules.ruleActionFilter)
  const ruleStatusFilter = useAppSelector(s => s.customRules.ruleStatusFilter)
  const isDesktop = useBreakpointIndex() === 2

  useEffect(() => {
    return () => {
      dispatch(setRuleActionFilter(AllActionType))
      dispatch(setRuleStatusFilter(AllStatusesType))
    }
  }, [dispatch])

  /* Folder Upload Logic */
  const uploadFolderInputRef = useRef<HTMLInputElement | null>(null)

  const onChooseFile = onChooseFileCreator(
    event => (event as ChangeEvent<HTMLInputElement>)?.target?.files?.[0],
  )

  const onUploadFolderClick = () => {
    // clear file input
    uploadFolderInputRef.current?.value && (uploadFolderInputRef.current.value = '')
    uploadFolderInputRef.current?.click()
  }

  const listOptions: SimpleActionMenuItemProps[] = [
    {
      ariaLabel: 'upload folder option',
      children: (
        <>
          <Input
            data-testid="import-group-hidden-input"
            type="file"
            ref={uploadFolderInputRef}
            sxWrapper={{
              display: 'none',
            }}
            onChange={onChooseFile as (event: React.ChangeEvent<HTMLInputElement>) => void}
          />
          <IconButtonContent startIcon={UploadIcon}>Upload Folder</IconButtonContent>
        </>
      ),
      onClick: onUploadFolderClick,
    },
    {
      ariaLabel: 'alphabetical option',
      children: (
        <IconButtonContent
          startIcon={SortIcon}
          endIcon={shouldSortRules ? CheckmarkIcon : undefined}
        >
          Alphabetical
        </IconButtonContent>
      ),
      isSelected: shouldSortRules,
      isTopDivider: true,
      sxDividerStyles: { mx: 0 },
      onClick: () => {
        dispatch(setShouldSortRules(true))
        hideRef.current?.()
      },
    },
    {
      ariaLabel: 'last updated option',
      children: (
        <IconButtonContent
          startIcon={LastUpdatedIcon}
          endIcon={!shouldSortRules ? CheckmarkIcon : undefined}
        >
          Last Updated
        </IconButtonContent>
      ),
      isSelected: !shouldSortRules,
      onClick: () => {
        dispatch(setShouldSortRules(false))
        hideRef.current?.()
      },
    },
    {
      ariaLabel: 'large list option',
      children: isDesktop ? null : (
        <IconButtonContent
          startIcon={LargeListIcon}
          endIcon={shouldShowLargeListView ? CheckmarkIcon : undefined}
        >
          Large List
        </IconButtonContent>
      ),
      isTopDivider: !isDesktop,
      isSelected: shouldShowLargeListView,
      onClick: () => {
        dispatch(setShouldShowLargeView(true))
        hideRef.current?.()
      },
    },
    {
      ariaLabel: 'small list option',
      children: isDesktop ? null : (
        <IconButtonContent
          startIcon={SmallListIcon}
          endIcon={shouldShowLargeListView ? undefined : CheckmarkIcon}
        >
          Small List
        </IconButtonContent>
      ),
      isSelected: !shouldShowLargeListView,
      onClick: () => {
        dispatch(setShouldShowLargeView(false))
        hideRef.current?.()
      },
    },
  ]

  return (
    <MoreButton
      hideRef={hideRef}
      options={listOptions}
      tabIndex={tabIndex}
      ariaLabel="custom rules options"
      dataTestId="custom-rules-options-button"
      appendToBoundaryElement="dashboard-sub-header"
      onHidden={() => {
        setIsAllActionsMenuVisible(false)
        setIsAllStatusesMenuVisible(false)
      }}
      dropdownContent={
        isAllActionsMenuVisible ? (
          <ActionsMenu
            hideRef={hideRef}
            selectedType={ruleActionFilter}
            setIsAllActionsMenuVisible={setIsAllActionsMenuVisible}
          />
        ) : isAllStatusesMenuVisible ? (
          <StatusMenu
            hideRef={hideRef}
            selectedStatus={ruleStatusFilter}
            setIsAllStatusesMenuVisible={setIsAllStatusesMenuVisible}
          />
        ) : undefined
      }
      customContent={
        <FilterByRuleItem
          setIsAllActionsMenuVisible={setIsAllActionsMenuVisible}
          setIsAllStatusesMenuVisible={setIsAllStatusesMenuVisible}
        />
      }
    />
  )
}
