import { useAppSelector } from 'store/hooks'
import { ROOT_GROUP, CustomRuleData, ruleApi } from 'store/api/rules'
import { useGetAllRulesQuery } from 'store/api/rules/rules'
import { AllActionType, AllStatusesType } from 'store/customRules'
import { wildcardSearch } from 'utils/wildcardSearch'
import Fuse from 'fuse.js'
import { defaultFuseOptions } from 'utils'
import { toASCII, toUnicode } from 'utils/punycode'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import { groupApi } from 'store/api/groups'

let fuseInstance

function createFuseInstance(rules, isRootGroup) {
  if (!(fuseInstance || (fuseInstance?.options.includeScore && isRootGroup))) {
    return new Fuse(rules ?? [], {
      ...defaultFuseOptions,
      includeScore: isRootGroup,
      keys: ['PK', 'comment'],
    })
  }

  return fuseInstance
}

export function useGetFilterRules(): CustomRuleData[] {
  const groupUserIsIn = useAppSelector(s => s.customRules.groupUserIsIn)
  const isRootGroup = groupUserIsIn === ROOT_GROUP
  const ruleActionFilter = useAppSelector(s => s.customRules.ruleActionFilter)
  const ruleStatusFilter = useAppSelector(s => s.customRules.ruleStatusFilter)
  const searchParam = useAppSelector(s => s.customRules.searchParam)
  const rulesProfile = useGetSelectedProfile()
  const { data: groupData } = groupApi.endpoints.getGroups.useQueryState({
    profileId: rulesProfile?.PK.toString() ?? '',
  })

  const { data: rulesData } = ruleApi.endpoints.getRules.useQueryState({
    groupPk: groupUserIsIn,
    profileId: rulesProfile?.PK.toString() ?? '',
  })
  const { data: allRulesData } = useGetAllRulesQuery(
    {
      profileId: rulesProfile?.PK.toString() ?? '',
    },
    { skip: !rulesProfile },
  )

  let filteredRules

  if (ruleActionFilter !== AllActionType) {
    const rules = isRootGroup ? allRulesData?.rules : rulesData?.rules

    filteredRules =
      rules?.filter(rule => {
        if (rule.group === groupUserIsIn) {
          return rule.action?.do === ruleActionFilter
        }

        const ruleGroupAction = groupData?.groups?.find(group => group.PK === rule.group)?.action

        return ruleGroupAction?.do === ruleActionFilter
      }) ?? []
  }

  if (ruleStatusFilter !== AllStatusesType) {
    const rules = filteredRules || (isRootGroup ? allRulesData?.rules : rulesData?.rules)

    filteredRules = rules?.filter(rule => rule.action?.status === ruleStatusFilter) ?? []
  }

  if (!searchParam) {
    return ruleActionFilter !== AllActionType || ruleStatusFilter !== AllStatusesType
      ? filteredRules
      : rulesData?.rules || []
  }

  const rules =
    ruleActionFilter !== AllActionType || ruleStatusFilter !== AllStatusesType
      ? filteredRules
      : isRootGroup
      ? allRulesData?.rules
      : rulesData?.rules

  if (searchParam.includes('*') || searchParam.includes('?')) {
    return rules?.filter(item => wildcardSearch(searchParam, toUnicode(item.PK))) || []
  }

  const fuzzySearchList = createFuseInstance(rules, isRootGroup)

  return (
    fuzzySearchList?.search(toASCII(searchParam)).map(matches => {
      return matches.item
    }) ?? []
  )
}
