import React, { ReactElement, useCallback } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { CustomRulesHeaderProps } from 'components/Dashboard/Profiles/CustomRules/Headers'
import GroupMenu from 'components/Dashboard/Profiles/CustomRules/Headers/GroupMenu'
import { CustomRuleData, ROOT_GROUP, useDeleteGroupRulesMutation } from 'store/api/rules'
import {
  deselectAllRules,
  searchRules,
  selectRulesToDelete,
  setRulesToEdit,
  toggleSelectionMode,
  updateCustomRuleDraft,
} from 'store/customRules'
import GroupOptionsSelector from 'components/Dashboard/Profiles/CustomRules/Headers/GroupMenu/GroupOptionsSelector'
import SearchBar from 'ui/SearchBar'
import OptionsMenu from 'components/Dashboard/Profiles/CustomRules/Headers/OptionsMenu'
import { sanitizeUrl } from 'utils/sanitizeUrl'
import { Button, Svg } from 'ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import CopyIcon from 'images/copy-icon.svg'
import MoveIcon from 'images/move-icon.svg'
import EditIcon from 'images/edit-icon.svg'
import DeleteIcon from 'images/delete-icon.svg'
import { useGetGroupsQuery } from 'store/api/groups'
import { useGetFilterRules } from 'components/Dashboard/Profiles/CustomRules/useGetFilterRules'
import { Subset } from 'utils'
import { batch } from 'react-redux'
import { DialogType } from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog'
import useQueryString from 'utils/useQueryString'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'

export default function DesktopHeader({
  onChooseFileCreator,
  selectedFolderPk,
  isLoading,
}: CustomRulesHeaderProps): ReactElement {
  const dispatch = useAppDispatch()
  const searchParam = useAppSelector(s => s.customRules.searchParam)
  const clearSearch = useCallback(() => {
    dispatch(searchRules(''))
  }, [dispatch])

  const {
    ruleSelection: { selectedRuleHostnames },
  } = useAppSelector(s => s.customRules)

  return (
    <Flex
      data-testid="desktop-header"
      sx={{
        width: '100%',
        display: ['none', 'flex'],
        alignItems: 'center',
        justifyContent: 'space-between',
        background: (theme): string => {
          return `linear-gradient(180deg, ${theme.colors?.blue800} 75.52%, rgba(18, 19, 28, 0) 100%)`
        },
        zIndex: 'zIndex240',
      }}
    >
      <Flex>{selectedFolderPk !== ROOT_GROUP && !isLoading && <GroupMenu />}</Flex>
      <Flex
        sx={{
          width: 'fit-content',
          alignItems: 'center',
          justifySelf: 'flex-end',
          gap: '1.2rem',
        }}
      >
        <SearchBar
          tabIndex={selectedRuleHostnames.length ? -1 : 0}
          name="rules"
          searchText={searchParam}
          onChange={(e): void => {
            if (e.nativeEvent.inputType !== 'insertFromPaste') {
              dispatch(searchRules(e.target.value))
            }
          }}
          onPaste={e => {
            dispatch(searchRules(sanitizeUrl(e.clipboardData?.getData('text') || '', true)))
          }}
          clearSearch={clearSearch}
        />
        {selectedFolderPk === ROOT_GROUP ? (
          <OptionsMenu
            onChooseFileCreator={onChooseFileCreator}
            tabIndex={selectedRuleHostnames.length ? -1 : 0}
          />
        ) : (
          <GroupOptionsSelector />
        )}
        <CustomRulesActionContainer />
      </Flex>
    </Flex>
  )
}

export function CustomRulesActionContainer(): ReactElement {
  const isDeleteAction = useAppSelector(s => s.customRules.ruleSelection.isDeleteAction)

  const {
    ruleSelection: { isSelecting, selectedRuleHostnames },
  } = useAppSelector(s => s.customRules)

  return (
    <Flex
      data-testid="action-buttons-container"
      sx={{
        width: isSelecting && selectedRuleHostnames.length ? ['17rem', '30rem'] : 0,
        height: '4.8rem',
        position: 'absolute',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: 'blue800',
        right: '4.2rem',
        overflow: 'hidden',
        transition: '0.5s width',
        mr: [0, '0.8rem'],
      }}
    >
      {isDeleteAction ? <CustomRulesConfirmDeletion /> : <CustomRuleButtons />}
    </Flex>
  )
}

export function CustomRuleButtons(): ReactElement {
  const dispatch = useAppDispatch()
  const { nav } = useQueryString()

  const hasNoNonRootFolders = useAppSelector(s => s.groups.data?.groups.length === 0)
  const rulesProfileId = useGetSelectedProfile()?.PK?.toString() ?? ''
  const { data: groupsData } = useGetGroupsQuery(
    { profileId: rulesProfileId },
    { skip: !rulesProfileId },
  )

  const filteredRules = useGetFilterRules()
  const {
    ruleSelection: { selectedRuleHostnames },
  } = useAppSelector(s => s.customRules)
  const groupUserIsIn = useAppSelector(s => s.customRules.groupUserIsIn)

  const handleEditClick = useCallback(
    (isCopyTray?: boolean): void => {
      const rulesToEdit = filteredRules.filter(r => selectedRuleHostnames.includes(r.PK))

      const draftRule: Subset<CustomRuleData> = {
        group: groupUserIsIn ?? ROOT_GROUP,
        PK: '',
        action: { do: undefined },
      }

      if (
        rulesToEdit?.every(
          rule =>
            rule.action.do === rulesToEdit[0].action.do &&
            rule.action.via === rulesToEdit[0].action.via,
        ) &&
        !!draftRule
      ) {
        draftRule.action = {
          do: rulesToEdit[0]?.action?.do,
          via: rulesToEdit[0]?.action?.via,
        }
        if (rulesToEdit.length === 1) {
          draftRule.PK = rulesToEdit[0].PK
        }
      }
      batch(() => {
        dispatch(setRulesToEdit(rulesToEdit))
        dispatch(updateCustomRuleDraft(draftRule as CustomRuleData))
      }),
        nav(
          { ruleDialog: isCopyTray ? DialogType.COPY : DialogType.RULE_OR_FOLDER },
          { shouldLeaveQueryString: true },
        )
    },
    [filteredRules, groupUserIsIn, nav, selectedRuleHostnames, dispatch],
  )

  return (
    <Flex
      className="rule-options"
      sx={{
        alignItems: 'center',
        zIndex: 'zIndex2',
        gap: '1.6rem',
      }}
    >
      <Button
        data-testid="copy-rule"
        ariaLabel="copy rule"
        onClick={() => handleEditClick(true)}
        variant="simple"
        tabIndex={selectedRuleHostnames.length ? 0 : -1}
        onKeyDown={stopPropagationOnKeyPress}
        sx={{
          p: 0,
        }}
      >
        <Svg
          svg={CopyIcon}
          fill="aliceBlue60Black60"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
            flexShrink: 0,
          }}
        />
      </Button>
      <Button
        disabled={!groupsData?.groups.length}
        data-testid="move-rule"
        ariaLabel="move rule"
        tabIndex={selectedRuleHostnames.length ? 0 : -1}
        onClick={(): void => {
          if (!hasNoNonRootFolders) {
            nav({ ruleDialog: DialogType.MOVE }, { shouldLeaveQueryString: true })
          }
        }}
        variant="simple"
        onKeyDown={stopPropagationOnKeyPress}
        sx={{
          p: 0,
          ':disabled': {
            backgroundColor: 'transparent',
            opacity: '0.25',
          },
        }}
      >
        <Svg
          svg={MoveIcon}
          fill="white"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
            opacity: 0.5,
            flexShrink: 0,
            ':hover': {
              opacity: hasNoNonRootFolders ? 0.5 : 1,
            },
          }}
        />
      </Button>
      <Button
        data-testid="edit-rule"
        ariaLabel="edit rule"
        tabIndex={selectedRuleHostnames.length ? 0 : -1}
        onClick={(): void => {
          handleEditClick?.()
        }}
        onKeyDown={stopPropagationOnKeyPress}
        variant="simple"
        sx={{
          p: 0,
        }}
      >
        <Svg
          svg={EditIcon}
          fill="white"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
            opacity: 0.5,
            flexShrink: 0,
            ':hover': {
              opacity: 1,
            },
          }}
        />
      </Button>
      <Button
        data-testid="delete-rule"
        ariaLabel="delete rule"
        tabIndex={selectedRuleHostnames.length ? 0 : -1}
        onClick={(): void => {
          dispatch(selectRulesToDelete())
        }}
        onKeyDown={stopPropagationOnKeyPress}
        variant="simple"
        sx={{ p: 0 }}
      >
        <Svg
          svg={DeleteIcon}
          fill="white"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
            opacity: 0.5,
            flexShrink: 0,
            ':hover': {
              opacity: 1,
            },
          }}
        />
      </Button>
    </Flex>
  )
}

export function CustomRulesConfirmDeletion({
  sx,
  onDelete,
  onCancel,
}: {
  sx?: ThemeUIStyleObject
  onDelete?: () => void
  onCancel?: () => void
}): ReactElement {
  const dispatch = useAppDispatch()
  const {
    ruleSelection: { selectedRuleHostnames },
    groupUserIsIn,
  } = useAppSelector(s => s.customRules)
  const rulesProfileId = useGetSelectedProfile()?.PK.toString() ?? ''
  const [deleteRules] = useDeleteGroupRulesMutation()

  const hostNamesCount = selectedRuleHostnames.length

  return (
    <Flex sx={{ gap: ['0.4rem', '0.8rem'], ...sx }}>
      <Button
        data-testid="confirm-rule-deletion-button"
        ariaLabel="confirm rule deletion"
        variant="transparent"
        sx={{
          px: ['0.8rem', '1.6rem'],
          height: '3.8rem',
          backgroundColor: 'white4',
          borderRadius: '10rem',
          '&:hover': {
            backgroundColor: 'white8',
          },
        }}
        onClick={async () => {
          if (onDelete) {
            return onDelete()
          }

          await deleteRules({
            body: { hostnames: selectedRuleHostnames },
            profileId: rulesProfileId,
            group: groupUserIsIn,
          })
          dispatch(toggleSelectionMode(false))
          dispatch(deselectAllRules())
        }}
      >
        <Text variant="size15Weight600" sx={{ color: 'pomegranate' }}>
          Delete {hostNamesCount > 1 ? `(${hostNamesCount})` : ''}
        </Text>
      </Button>
      <Button
        variant="transparent"
        data-testid="cancel-deletion-rule-button"
        ariaLabel="cancel deletion"
        sx={{
          px: ['0.8rem', '1.6rem'],
          height: '3.8rem',
          backgroundColor: 'white4',
          borderRadius: '10rem',
          '&:hover': {
            backgroundColor: 'white8',
          },
        }}
        onClick={() => {
          if (onCancel) {
            return onCancel()
          }

          dispatch(toggleSelectionMode(false))
          dispatch(deselectAllRules())
        }}
      >
        <Text variant="size15Weight600" sx={{ color: 'aliceBlue60' }}>
          Cancel
        </Text>
      </Button>
    </Flex>
  )
}
