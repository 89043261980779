import URLSafeBase64 from 'urlsafe-base64'
import Buffer from 'buffer'

export default function getDnsStamp({
  resolverId,
  host,
  ip,
}: {
  resolverId: string
  host: string
  ip: string
}): string {
  if (!resolverId || !host) {
    return ''
  }

  const dnssec = true
  const nolog = true
  const nofilter = true
  const props = (+dnssec << 0) | (+nolog << 1) | (+nofilter << 2)
  const dnsStamp = {
    type: 'DoH',
    hostName: host,
    path: `/${resolverId}`,
  }

  const v = [0x02, props, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]
  const addr = ip.split('').map(c => c?.charCodeAt(0))
  v.push(addr.length, ...addr)
  const hash = Buffer.Buffer.from(''.replace(/[: \t]/g, ''), 'hex')
  v.push(hash.length)
  const hostName = dnsStamp.hostName?.split('')?.map(c => c?.charCodeAt(0))
  v.push(hostName.length, ...hostName)
  const path = dnsStamp.path?.split('')?.map(c => c?.charCodeAt(0))
  v.push(path.length, ...path)

  //@ts-ignore
  return `sdns://${URLSafeBase64.encode(Buffer.Buffer(v))}`
}
