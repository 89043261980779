import React, { ElementType, MutableRefObject, ReactElement, useMemo } from 'react'
import { Flex, ThemeUIStyleObject, Text } from 'theme-ui'
import { Button, IconButtonContent, IconMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import CheckIcon from 'images/analytics/check.svg'
import { Setter } from 'utils'
import BackIconSvg from 'images/back-icon-big.svg'
import { AllStatusesType, RuleStatusFilter, setRuleStatusFilter } from 'store/customRules'
import { useAppDispatch } from 'store/hooks'
import { EnabledStatus } from 'store/api/rules'

interface LineDisplayConfig {
  label: string
  icon?: ElementType
}

export const displayConfigByType: Record<EnabledStatus, LineDisplayConfig> = {
  [EnabledStatus.ENABLED]: { label: 'Enabled' },
  [EnabledStatus.DISABLED]: { label: 'Disabled' },
}

const ruleStatusFilters: RuleStatusFilter[] = [
  AllStatusesType,
  EnabledStatus.ENABLED,
  EnabledStatus.DISABLED,
]

export const ruleStatusToDropdownItemData: Record<
  RuleStatusFilter,
  { label: string; icon?: ElementType }
> = {
  all: { label: 'All Statuses' },
  ...displayConfigByType,
}

export default function StatusMenu({
  hideRef,
  selectedStatus,
  setIsAllStatusesMenuVisible,
  sx,
}: {
  hideRef: MutableRefObject<(() => void) | undefined>
  selectedStatus: RuleStatusFilter
  setIsAllStatusesMenuVisible: Setter<boolean>
  sx?: ThemeUIStyleObject
}): ReactElement {
  const dispatch = useAppDispatch()

  const queriesOptions: SimpleActionMenuItemProps[] = useMemo(() => {
    const options: SimpleActionMenuItemProps[] =
      ruleStatusFilters.map((status): SimpleActionMenuItemProps => {
        const isSelected = status === selectedStatus
        return {
          dataTestId: `${status}-rule-status-menu-item`,
          ariaLabel: `${status} option`,
          isSelected,
          isBackgroundHighlighted: true,
          children: (
            <IconButtonContent
              startIcon={ruleStatusToDropdownItemData[status].icon}
              endIcon={isSelected ? CheckIcon : undefined}
            >
              <Text variant="size15Weight600">{ruleStatusToDropdownItemData[status].label}</Text>
            </IconButtonContent>
          ),

          onClick: () => {
            dispatch(setRuleStatusFilter(status))
            hideRef.current?.()
          },
        }
      }) ?? []

    return options
  }, [dispatch, hideRef, selectedStatus])

  return (
    <Flex
      sx={{
        width: ['100%', '26.4rem'],
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          textAlign: 'center',
          color: 'aliceBlue',
          justifyContent: 'center',
          alignItems: 'center',
          pt: '0.4rem',
          pb: '0.4rem',
          pointerEvents: 'visible',
        }}
      >
        <Button
          ariaLabel="back button"
          variant="simple"
          sx={{
            position: 'absolute',
            top: '0.8rem',
            left: '0.4rem',
            p: 0,
            pointerEvents: 'visible',
            opacity: 0.6,
            ':hover:not([disabled])': {
              opacity: 1,
              svg: {
                opacity: 1,
                path: {
                  opacity: 1,
                  color: 'aliceBlue',
                },
              },
            },
          }}
          onClick={() => setIsAllStatusesMenuVisible(false)}
        >
          <Svg svg={BackIconSvg} fill="aliceBlue" sx={{ width: '1.6rem', height: '1.6rem' }} />
        </Button>
        <Text variant="size15Weight700" sx={{ opacity: 0.6 }}>
          Actions
        </Text>
      </Flex>
      <IconMenu
        dataTestId="rule-status-menu-content"
        hideRef={hideRef}
        items={queriesOptions}
        sxButton={{
          pr: '0.8rem',
          minWidth: '22rem',
          ':hover': {
            fill: 'none',
            opacity: 1,
          },
        }}
        sxContainer={{
          border: 0,
          p: '0.4rem',
        }}
      />
    </Flex>
  )
}
