import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import TabOutline from 'components/SetupGuide/ResolverTabs/Components/UserResolver/TabOutline'
import {
  ResolverType,
  SetupGuideIntermediateStates,
} from 'components/SetupGuide/SetupGuide.interface'
import ResolverContainer from 'components/SetupGuide/ResolverTabs/Components/UserResolver/ResolverContainer'
import ResolverTabTitle from 'components/SetupGuide/ResolverTabs/Components/ResolverTabTitle'
import LegacyResolver from 'components/SetupGuide/ResolverTabs/Components/UserResolver/LegacyResolver'
import { Box } from 'theme-ui'
import SecureResolver, {
  bootstrapIps,
} from 'components/SetupGuide/ResolverTabs/Components/UserResolver/SecureResolver'
import { ResolverTabData } from 'components/SetupGuide/ResolverTabs/ResolverTabs'
import { freeDnsPagePath } from 'components/FreeDNSPage/FreeDNSPage'
import StandardConfigurationsDropdown from '../UserResolver/StandardConfigurationsDropdown'
import { HighlightProtocolInSetupGuide } from 'store/api/devices/devices.interface'
import useQueryString from 'utils/useQueryString'
import getDnsStamp from '../getDnsStamp'
import { DNS_DOMAIN } from 'gatsby-env-variables'

export default function ResolverTabContent({
  resolverTabData,
  highlight,
  deviceSelector,
}: {
  resolverTabData: ResolverTabData
  highlight?: HighlightProtocolInSetupGuide[]
  deviceSelector?: ReactElement
}): ReactElement {
  const location = useLocation()
  // this state is used to keep track of which tab is open
  const [activeType, setActiveType] = useState<ResolverType | undefined>()
  const alwaysOpen = !highlight
  const shouldShowDropdownButton = Object.entries(resolverTabData).length > 1
  const { qs } = useQueryString()
  const isEndpointPanel = location.pathname.includes('endpoints')
  const isEndpointResolverScreen =
    isEndpointPanel && qs.helpPane === SetupGuideIntermediateStates.DNS
  const dnsStamp = getDnsStamp({
    resolverId: resolverTabData.secure.resolverId || '',
    host: DNS_DOMAIN,
    ip: bootstrapIps[ResolverType.DOH][0],
  })

  useEffect(() => {
    if (
      highlight?.includes(HighlightProtocolInSetupGuide.DOH) ||
      highlight?.includes(HighlightProtocolInSetupGuide.DOT) ||
      highlight?.includes(HighlightProtocolInSetupGuide.ResolverID)
    ) {
      setActiveType(ResolverType.SECURE)
    }
    if (
      resolverTabData.legacy &&
      (highlight?.includes(HighlightProtocolInSetupGuide.IPv4) ||
        highlight?.includes(HighlightProtocolInSetupGuide.IPv6))
    ) {
      setActiveType(ResolverType.LEGACY)
    }
  }, [highlight, resolverTabData.legacy])

  return (
    <TabOutline
      dropdown={
        deviceSelector ||
        (location.pathname.includes(freeDnsPagePath) && <StandardConfigurationsDropdown />)
      }
    >
      {resolverTabData.legacy && (
        <ResolverContainer
          type={ResolverType.LEGACY}
          title={<ResolverTabTitle type={ResolverType.LEGACY} />}
          isOpen={(!activeType && alwaysOpen) || activeType === ResolverType.LEGACY}
          setIsOpen={setActiveType}
          shouldShowDropdownButton={shouldShowDropdownButton}
        >
          <LegacyResolver
            highlight={!isEndpointResolverScreen ? highlight : undefined}
            legacyResolverData={resolverTabData.legacy}
          />
        </ResolverContainer>
      )}

      <ResolverContainer
        type={ResolverType.SECURE}
        title={<ResolverTabTitle type={ResolverType.SECURE} />}
        isOpen={
          (!activeType && (!resolverTabData.legacy || alwaysOpen)) ||
          activeType === ResolverType.SECURE
        }
        setIsOpen={setActiveType}
        shouldShowDropdownButton={shouldShowDropdownButton}
      >
        <>
          {Object.entries(resolverTabData.secure).map(([type, address]) => (
            <Box
              key={type}
              data-testid={type}
              sx={{
                listStyle: 'none',
                m: 0,
                backgroundColor: 'transparent',
                color: highlight?.includes(type as HighlightProtocolInSetupGuide)
                  ? 'greenApple'
                  : 'aliceBlue60',
              }}
            >
              <SecureResolver
                type={type}
                isResolverTypeHighlighted={
                  !!highlight?.includes(type as HighlightProtocolInSetupGuide) &&
                  !isEndpointResolverScreen
                }
                address={address}
                canCopy={true}
              />
            </Box>
          ))}
          {dnsStamp && (
            <SecureResolver
              type="dnsStamp"
              isResolverTypeHighlighted={false}
              address={dnsStamp}
              canCopy={true}
            />
          )}
        </>
      </ResolverContainer>
    </TabOutline>
  )
}
