import React, { ReactElement, useMemo, useRef } from 'react'
import { SimpleActionMenuItemProps, Svg } from 'ui'
import { Flex } from 'theme-ui'
import CheckIcon from 'images/analytics/check.svg'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'
import { useGetDevicesTypesQuery } from 'store/api/devices'
import DeviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'
import {
  DeviceGroup,
  DeviceSettings as DeviceSettingsType,
} from 'store/api/devices/devices.interface'
import useGetUserState from 'store/api/user/useGetUserState'
import useQueryString, { LocationSearch } from 'utils/useQueryString'
import omit from 'lodash/omit'

const otherDeviceGroupName = {
  [DeviceGroup.BROWSER]: 'Other Browser',
  [DeviceGroup.TV]: 'Other TV',
  [DeviceGroup.ROUTER]: 'Other Router',
}

export const otherType = 'other'

export default function DeviceTypeDropdown({
  selectedType,
  setSelectedType,
  isClient,
  shouldShowOtherOption,
  boundaryElementTestId,
  shouldUpdateQueryString = true,
}: {
  selectedType?: string
  setSelectedType?: (type: string) => void
  isClient?: boolean
  shouldShowOtherOption?: boolean
  boundaryElementTestId?: string
  shouldUpdateQueryString?: boolean
}): ReactElement {
  const { qs, nav } = useQueryString()
  const hideRef = useRef<() => void>()
  const { isOrganization } = useGetUserState()
  const { data: typesData } = useGetDevicesTypesQuery('')

  const types = useMemo(() => {
    const typesArray = Object.values(typesData?.types || [])

    return isOrganization
      ? typesArray.filter(data => {
          if (isClient) {
            return data.group !== DeviceGroup.ROUTER
          }

          return data.group === DeviceGroup.ROUTER
        })
      : typesArray
  }, [isClient, isOrganization, typesData?.types])

  const options: SimpleActionMenuItemProps[] = useMemo(() => {
    const mainOptions =
      types
        .reduce((current, settings) => [...current, ...settings.icons], [] as DeviceSettingsType[])
        .map(deviceType => ({
          ariaLabel: `${deviceType.name} option`,
          isSelected: deviceType.type === selectedType,
          isBackgroundHighlighted: true,
          searchBy:
            deviceType.name === 'Other'
              ? otherDeviceGroupName[deviceType.type.split('-')[0]]
              : deviceType.name,
          buttonContent: (
            <Svg
              svg={DeviceIcons[deviceType.type || defaultIconName]}
              fill="aliceBlue"
              sx={{ flexShrink: 0, width: '1.6rem', height: '1.6rem' }}
            />
          ),
          children: (
            <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <Flex sx={{ alignItems: 'center', gap: '1.2rem' }}>
                <Svg
                  svg={DeviceIcons[deviceType.type || defaultIconName]}
                  fill="aliceBlue"
                  sx={{ flexShrink: 0, width: '2.4rem', height: '2.4rem' }}
                />
                <Flex
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    fontSize: '1.6rem',
                    fontWeight: 'bold',
                  }}
                >
                  {deviceType.name === 'Other'
                    ? otherDeviceGroupName[deviceType.type.split('-')[0]]
                    : deviceType.name}
                </Flex>
              </Flex>
              {deviceType.type === selectedType && (
                <Svg svg={CheckIcon} fill="aliceBlue60" sx={{ flexShrink: 0 }} />
              )}
            </Flex>
          ),
          onClick: () => {
            setSelectedType?.(deviceType.type)

            if (shouldUpdateQueryString) {
              const queryString = omit(qs, [
                'legacyIpv4Status',
                'stats',
                'learnIp',
              ]) as LocationSearch

              if (deviceType.settings?.legacy_ipv4_status !== undefined) {
                queryString.legacyIpv4Status = deviceType.settings.legacy_ipv4_status
              }

              if (deviceType.settings?.stats !== undefined) {
                queryString.stats = deviceType.settings.stats
              }

              if (deviceType.settings?.learn_ip !== undefined) {
                queryString.learnIp = deviceType.settings.learn_ip
              }

              nav({
                ...queryString,
              })
            }

            hideRef.current?.()
          },
        })) || []

    const otherOption = {
      ariaLabel: 'other option',
      isSelected: otherType === selectedType,
      isBackgroundHighlighted: true,
      searchBy: 'Other Endpoint',
      buttonContent: (
        <Svg
          svg={DeviceIcons[defaultIconName]}
          fill="aliceBlue"
          sx={{ flexShrink: 0, width: '1.6rem', height: '1.6rem' }}
        />
      ),
      children: (
        <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Flex sx={{ alignItems: 'center', gap: '1.2rem' }}>
            <Svg
              svg={DeviceIcons[defaultIconName]}
              fill="aliceBlue"
              sx={{ flexShrink: 0, width: '2.4rem', height: '2.4rem' }}
            />
            <Flex
              sx={{
                height: '100%',
                alignItems: 'center',
                fontSize: '1.6rem',
                fontWeight: 'bold',
              }}
            >
              Other Endpoint
            </Flex>
          </Flex>
          {otherType === selectedType && (
            <Svg svg={CheckIcon} fill="aliceBlue60" sx={{ flexShrink: 0 }} />
          )}
        </Flex>
      ),
      onClick: () => {
        setSelectedType?.(otherType)

        nav({ ...omit(qs, ['legacyIpv4Status', 'stats', 'learnIp']) })

        hideRef.current?.()
      },
    }

    return shouldShowOtherOption ? [...mainOptions, otherOption] : mainOptions
  }, [
    shouldUpdateQueryString,
    types,
    selectedType,
    shouldShowOtherOption,
    setSelectedType,
    qs,
    nav,
  ])

  return (
    <SimpleDropdown
      isSearchVisible
      testId="device-types-dropdown"
      ariaLabel="device types dropdown"
      items={options}
      hideRef={hideRef}
      label="Type"
      boundaryElementTestId={boundaryElementTestId || 'device-dialog'}
      contentMaxHeight="23rem"
      sxDropdownContent={{
        width: '26.4rem',
      }}
      sx={{
        flexShrink: 0,
      }}
    />
  )
}
